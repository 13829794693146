import {Box, Stack, Typography, useMediaQuery} from "@mui/material";
import React from "react";
import {winstonTheme} from "../theme/winstonTheme";
import {useTranslation} from "react-i18next";

const DedicatedTeam = ({sectionRefs}) => {
    const {t} = useTranslation()
    const mobile = useMediaQuery(winstonTheme.breakpoints.between("xs", "sm"));
    const mdXl = useMediaQuery(winstonTheme.breakpoints.between("md", "xl"));

    return (
        <Box px={4} position={"relative"} display={'flex'} justifyContent={'center'}>
            <Box id={'dedicatedTeam'} ref={sectionRefs[4]} position={"absolute"}
                 top={!mobile ? '-130px' : '-120px'}>
                <img style={{
                    maxWidth: !mobile ? '300px' : '200px',
                    borderStyle: 'solid',
                    borderRadius: '15%',
                    borderWidth: 5,
                    borderColor: winstonTheme.palette.primary.main
                }}
                     src={require('../assets/images/dedicated_team_cover.jpeg')}/>
            </Box>
            <Stack mt={'110px'} pt={!mobile ? 12 : 0} alignItems={'center'}>
                <Typography color={'primary'}
                            variant={mdXl ? 'h3' : 'h4'} fontWeight={'bold'}
                            gutterBottom textAlign={'center'}>
                    {t('dedicatedTeam.title')}
                </Typography>
                <Typography variant={'h5'} textAlign={'center'} fontWeight={300} sx={{maxWidth: '480px'}}>
                    {t('dedicatedTeam.subtitle')}
                </Typography>
            </Stack>
        </Box>
    );
}

export default DedicatedTeam