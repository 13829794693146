import React, {useState} from "react";
//import {InlineWidget} from "react-calendly";
import {Box, Button, CircularProgress, Paper, Stack, TextField, Typography} from "@mui/material";
import {ArrowForwardIos} from "@mui/icons-material";
import {API_URL} from "../config";
import useAxios from "axios-hooks";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const RichiediDemo = ({ sectionRefs }) => {
    const {t, i18n} = useTranslation()

    const navigate = useNavigate()

    const [sending, setSending] = React.useState(false)
    const [dati, setDati] = useState({
        nome: '',
        email: '',
        telefono: ''
    })
    const [{data: data, loading: sendingEmail, error: errorEmail}, sendEmail] = useAxios({},
        {manual: true}
    )

    const handleSendEmail = async () => {
        //window.open("https://calendly.com/plesh/call-informativa-winston");

        await sendEmail({
            data: {nome: dati.nome, email: dati.email, telefono: dati.telefono, lang: i18n?.language?.slice(0,2) ?? ''},
            url: `${API_URL}`,
            method: "POST"
        }).then(() => {
            navigate('/grazie-informazioni-winston')
        })
    }

    const handleChange = (event, field) => {
        setDati({
            ...dati,
            [field]: event.target.value
        })
    }

    return (
        <Stack id={'richiedi-demo'} ref={sectionRefs[5]} alignItems={'center'} width={'100%'} py={2}>
            {/*<InlineWidget styles={{height:'100%'}} url="https://calendly.com/plesh/call-informativa-winston"/>*/}
            {
                sending ?
                    <Box sx={{width:'100%'}}>
                        <Box sx={{textAlign: 'center'}}>
                            <CircularProgress sx={{color:'white'}}/>
                        </Box>
                    </Box> : null
            }
            <Stack mx={2} my={10} py={9} px={{xs: 1, sm: 7}} alignItems={'center'} component={Paper}
                   variant={'demo'}>
                <img src={require('../assets/images/logo_circle.png')} width={'45px'} style={{marginBottom: 8}}/>
                <Typography gutterBottom textAlign={'center'} variant={'h4'} fontWeight={'bold'}>
                    {t('demo.title')}
                </Typography>
                <Typography variant={'h6'} textAlign={'center'}
                            fontWeight={300}
                            sx={{maxWidth: '350px', paddingInline: 2}}>
                    {t('demo.subtitle')}
                </Typography>
                <Stack mt={3} spacing={1} width={'70%'}>
                    <TextField fullWidth variant={'outlined'}
                               placeholder={`${t('demo.name')} *`}
                               required
                               value={dati.nome}
                               onChange={(event) => handleChange(event, 'nome')}
                               type={'text'}/>
                    <TextField fullWidth variant={'outlined'}
                               placeholder={`${t('demo.email')} *`}
                               required
                               value={dati.email}
                               onChange={(event) => handleChange(event, 'email')}
                               type={'email'}/>
                    <TextField fullWidth variant={'outlined'}
                               placeholder={t('demo.phone')}
                               value={dati.telefono}
                               onChange={(event) => handleChange(event, 'telefono')}
                    />
                    <Button variant={'contained'}
                            disabled={!dati.nome ||
                                !dati.email || !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(dati.email))}
                            onClick={handleSendEmail}
                            endIcon={<ArrowForwardIos />}
                            sx={{paddingInline: 3 }}
                    >
                        {t('demo.requestDemo').toUpperCase()}
                    </Button>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default RichiediDemo;
