import React from "react";
import {Outlet, useParams} from "react-router-dom";
import i18n from "i18next";

export const LanguageRoute = ({children}) => {
    const {lang} = useParams()

    i18n.changeLanguage(lang)

    return (
        <div>
            {
                children ?
                    children
                    :
                    <Outlet/>
            }
        </div>
    );
}
