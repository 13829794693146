import React from "react";
import {Box, Card, lighten, Typography} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import {winstonTheme} from "../theme/winstonTheme";
import {useTranslation} from "react-i18next";

const CustomCard = ({variant, content, handleScrollToRef}) => {
    const {t} = useTranslation()
    return (
        <Card variant={variant}
              onClick={handleScrollToRef}
              sx={{
                  p: {xs: 1.5, sm: 2},
                  height: '100%',
                  textAlign: {sm: 'center', xs: 'left'},
                  position: {lg: 'relative', md: 'relative', sm: 'relative'},
                  display: {lg: 'inherit', md: 'inherit', sm: 'inherit', xs: 'flex'},
                  justifyContent: {lg: 'inherit', md: 'inherit', sm: 'inherit', xs: 'space-between'},
                  alignItems: 'center'
              }}>
            <Box flex={1} display={'flex'} justifyContent={'center'}>
                <img style={{maxHeight: '6.125rem', padding: content.id === 'servizi_checkin' ? 14 : 18}}
                     src={require('../assets/images/servizi/icons/' + content.icon)}/>
            </Box>
            <Typography gutterBottom
                        variant={'subtitle2'}
                        fontWeight={'bold'}
                        fontSize={{xs: '1.2rem', sm: '1.05rem'}}
                        flex={2}
            >
                {t(`services.${content.id}Label`)?.toUpperCase()}
            </Typography>
            <Box position={{lg: 'absolute', md: 'absolute', sm: 'absolute'}}
                 bottom={-4} left={0} right={0}>
                <ExpandMore fontSize={'large'} sx={{
                    color: lighten(variant?.includes('selected') ?
                        winstonTheme.palette.primary.main
                        : winstonTheme.palette.secondary.main, 0.2) }}/>
            </Box>
        </Card>)
}

export default CustomCard