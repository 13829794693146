import './App.css';
import {Box, CssBaseline, Stack, ThemeProvider, useMediaQuery} from "@mui/material";
import {winstonTheme} from "./theme/winstonTheme";
import Header from "./components/Header";
import Home from "./pages/Home";
import {Route, Routes} from "react-router-dom";
import logo_mobile_big from "./assets/images/logo_header.png";
import {Footer} from "./components/Footer";
import React, {useRef} from "react";
import {LangSwitcher} from "./components/LangSwitcher";
import {LanguageRoute} from "./components/LanguageRoute";
import ThankYouPage from "./pages/ThankYouPage";

export const CustomHeader = ({matches, sectionRefs}) => {
    return (
        <div>
            {matches && <Header sectionRefs={sectionRefs}/>}
            <Stack p={3} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <img style={{
                    display: matches ? 'none' : 'flex',
                    maxWidth: '150px'
                }} src={logo_mobile_big}/>
                <Box sx={{display: matches ? 'none' : 'inherit', }}>
                    <LangSwitcher/>
                </Box>
            </Stack>
        </div>
    )
}

function App() {

    return (
        <ThemeProvider theme={winstonTheme}>
            <CssBaseline/>
            <div className="App">
                <Routes>
                    <Route element={<LanguageRoute/>}>
                        <Route path={'/:lang'} element={<Home/>}/>
                        <Route path={'/:lang/grazie-informazioni-winston'} element={<ThankYouPage/>}/>
                    </Route>
                    <Route path={'/'} element={<Home/>}/>
                    <Route path={'/grazie-informazioni-winston'} element={<ThankYouPage/>}/>
                </Routes>
            </div>
        </ThemeProvider>
    );
}

export default App;
