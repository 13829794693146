import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import logo from '../assets/images/logo_header.png'
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import {Box, Button, Container, Divider, Stack} from "@mui/material";
import {Link} from "react-router-dom";
import {winstonTheme} from "../theme/winstonTheme";
import useScrollSpy from 'react-use-scrollspy';
import {useTranslation} from "react-i18next";
import {LangSwitcher} from "./LangSwitcher";

export function HideOnScroll(props) {
    const {children, window} = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger} timeout={500}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};


export default function Header(props) {
    const {t} = useTranslation()

    const activeSection = useScrollSpy({
        sectionElementRefs: props.sectionRefs, // Array of References to DOM elements
        offsetPx: -290,
        throttleMs: 10
    });

    return (
        //<HideOnScroll {...props}>
            <AppBar>
                <Toolbar sx={{alignItems: 'flex-end'}}>
                    <Container maxWidth={"xxl"}>
                        <Stack paddingX={0} direction={"row"} justifyContent={"space-between"} alignContent={"center"} alignItems={'center'}>
                            <Box component={Link} to={'/'}>
                                <img style={{maxWidth:'150px', maxHeight:'44px'}} src={logo} />
                            </Box>
                            <Stack direction={"row"}
                                    justifyContent={"space-between"}
                                    spacing={1}
                                    alignItems={"baseline"}>
                                    <div>
                                        <Button variant={'menu'} href={'#clienti'}>
                                        <p style={{
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            fontWeight: 400,
                                            fontSize:'1.25rem',
                                            color: activeSection === 1 ? winstonTheme.palette.primary.main : 'inherit'
                                        }}>{t('menu.clients')}</p>
                                        </Button>
                                        {//location.hash.includes('clienti') &&
                                            activeSection === 1 &&
                                            <Divider color={winstonTheme.palette.primary.main}
                                                  variant={'fullWidth'}/>}
                                    </div>
                                    <div>
                                        <Button variant={'menu'} href={'#funzioni'}>
                                        <p style={{
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            fontWeight: 400,
                                            fontSize:'1.25rem',
                                            color: activeSection === 2 ? winstonTheme.palette.primary.main : 'inherit'
                                        }}>{t('menu.features')}</p>
                                        </Button>
                                        {//location.hash.includes('funzioni') &&
                                            activeSection === 2 && <Divider color={winstonTheme.palette.primary.main}
                                                  variant={'fullWidth'}/>}
                                    </div>
                                    <div>
                                        <Button variant={'menu'} href={'#dedicatedTeam'}>
                                        <p style={{
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            fontWeight: 400,
                                            fontSize:'1.25rem',
                                            color: activeSection === 4 ? winstonTheme.palette.primary.main : 'inherit'
                                        }}>{t('menu.dedicatedTeam')}</p>
                                        </Button>
                                        {//location.hash.includes('dedicatedTeam') &&
                                            activeSection === 4 &&
                                            <Divider color={winstonTheme.palette.primary.main}
                                                  variant={'fullWidth'}/>}
                                    </div>
                                <Button href={'#richiedi-demo'}
                                    sx={{
                                        display: {sm: 'none', md: 'inherit'},
                                        color: 'white',
                                    }}
                                        variant={activeSection === 5 ? "outlinedSelected" : "outlined"}
                                >
                                    {t('menu.tryIt')}
                                </Button>
                                <Stack sx={{height:'100%', pl: 2}} justifyContent={'center'} alignContent={'center'}>
                                    <LangSwitcher/>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Container>
                </Toolbar>
            </AppBar>
        //</HideOnScroll>
    );
}
