import React from "react";
import {InlineWidget} from "react-calendly";
import {Box, Container} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";

const ThankYouPage = () => {
    const {t} = useTranslation()

    return (
        <Container>
            <Box sx={{width:'min(15rem, 100%)', margin:'auto', pt:4}}>
                <img style={{width:'100%'}} src={require('../assets/images/logo_header.png')}/>
            </Box>
            <Box sx={{pt:2.5}}>
                <Typography variant={'h6'} textAlign={'center'}>
                    {t('thankYouPage.text1')}
                    <br/>
                    {t('thankYouPage.text2')}
                </Typography>
            </Box>
            <Box style={{height:'80vh'}}>
                <InlineWidget styles={{height:'100%'}} url="https://calendly.com/plesh/call-informativa-winston"/>
            </Box>
        </Container>
    );
};

export default ThankYouPage;
