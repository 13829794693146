import {useEffect, useState} from "react";
import {Box, Button, useMediaQuery} from "@mui/material";
import {ArrowForwardIos} from "@mui/icons-material";
import {winstonTheme} from "../theme/winstonTheme";
import {useTranslation} from "react-i18next";
import {LangSwitcher} from "./LangSwitcher";

export const FloatingButton = () => {
    const {t} = useTranslation()
    const mobile = useMediaQuery(winstonTheme.breakpoints.between("xs", "md"));

    const [display, setDisplay] = useState('block')

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('richiedi-demo');
        if (wrappedElement.getBoundingClientRect().top < window.innerHeight * 0.85) {
            document.removeEventListener('scroll', trackScrolling);
            setDisplay('none')
        } else {
            setDisplay('block')
        }

        /*each(cards, (section) => {
            const sectionEl = document.getElementById(section.id);
            if (sectionEl.getBoundingClientRect().top <= (window.innerHeight || document.documentElement.clientHeight)*0.65) {
                setSelected(section.id)
                document.removeEventListener('scroll', trackScrolling);
            }
        })*/

    };

    useEffect(() => {
        if(mobile) {
            window.addEventListener('scroll', trackScrolling);
            return () => document.removeEventListener('scroll', trackScrolling);
        }
    }, [])

    return(
        <Box sx={{
            position: 'sticky',
            bottom: {xs: 0, sm: 20},
            display: {xs: display, sm: display, md: 'none'},
            width: '100%',
            maxWidth: '26rem',
            margin: 'auto',
            py: 3,
            px: 1
        }}>
            <Button href='#richiedi-demo'
                    sx={{fontSize: '1.5rem'}}
                    variant={"contained"}
                    endIcon={<ArrowForwardIos />}>
                {t('demo.title')}
            </Button>
        </Box>
    )
}
