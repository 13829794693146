import {createTheme} from "@mui/material";

export const winstonTheme = createTheme({
    typography: {
        fontFamily: ['Public Sans', 'Neue Montreal']
    },

    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    background:'#252a33',
                    borderRadius:'0',
                    borderColor:'#252a33',
                    boxShadow:'none',
                    '&:hover': {
                        background:'#252a33',
                        boxShadow:'none'
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "#252a33",
                },
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: "#252a33",
                    "& input": {
                        textAlign: "center"
                    }
                },
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    background: 'white',
                    borderRadius: '1rem',
                    borderColor: '#252a33',
                    boxShadow: 'none',
                    '& fieldset': {
                        borderRadius: `1rem`,
                        textAlign: "center"
                    },
                }
            }
        },
        MuiCard: {
            variants: [
                {
                    props: {variant: 'servizi'},
                    style: {
                        background: '#252a33',
                        border: '3px solid #323946',
                        boxShadow: 'inset 0em 0px 18px 1px rgba(106,120,147,0.1)',
                        '&:hover': {
                            background:'#2f3541',
                            color: 'white',
                            boxShadow: '0em 0px 6px 0px rgba(248,36,59,0.3)',
                        }
                    }
                },
                {
                    props: {variant: 'servizi-selected'},
                    style: {
                        background:'#313744',
                        color: 'white',
                        border: '3px solid rgba(248,36,59,0.35)',
                        borderRadius: "2.35rem",
                        boxShadow: '0em 0px 6px 0px rgba(248,36,59,0.3)',
                    }
                },
                {
                    props: {variant: 'servizi-dedicated-team'},
                    style: {
                        background:'rgba(248,36,59,0.70)',
                        color: 'white',
                        border: '3px solid rgba(248,36,59,0.35)',
                        borderRadius: "2.35rem",
                        boxShadow: '0em 0px 6px 0px rgba(248,36,59,0.3)',
                    }
                }
            ]
        },
        MuiPaper: {
            styleOverrides:{
                root: {
                    borderRadius:'2rem',
                },
                gradient: {
                    background: 'linear-gradient(-45deg, rgba(28, 28, 61,1) 0%, rgba(17, 17, 44,1) 100%) 0',
                    border: '2px solid #4C4C7E',
                    transition:'0.5s ease',
                    '&:hover': {
                        background: 'linear-gradient(-45deg, rgba(28, 28, 61,1) 20%, rgba(17, 17, 44,1) 100%) 100% / 200%',
                        boxShadow:'0px 0px 13px 0px rgba(76,76,126,0.72)'
                    },
                },
                swipe: {
                    background: 'linear-gradient(0deg, rgba(28, 28, 61,1) 0%, rgba(17, 17, 44,1) 100%) 0',
                    border: '2px solid #393462',
                    transition:'0.5s ease',
                    boxShadow:'0px 0px 20px 0px rgba(0,0,0,0.46)'
                },
                demo: {
                    background: '#252a33',
                    border: '2px solid #323946',
                    borderRadius: "2rem",
                    boxShadow: 'inset 0em 0px 18px 1px rgba(106,120,147,0.1)',
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius:'1rem',
                    fontWeight:'400',
                    textTransform:'none',
                    fontSize:'1.2rem',
                    padding:'6px 40px'
                },
                contained: {
                    backgroundColor: '#f8243b',
                    paddingBlock: 12,
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    width: '100%',
                    boxShadow: '0px 0px 12px 1px rgba(0,0,0,0.30)',
                    '&:disabled': {
                        backgroundColor: 'rgba(248,36,59,0.5)',
                        color: 'rgba(255,255,255,0.5)'
                    }
                },
                outlined: {
                    border: '0.5px solid #adadad',
                    background: '#2b3039',
                    '&:hover': {
                        background: 'transparent',
                        border: '1px solid #f8243b',
                        boxShadow: '0em 0px 6px 0px #f8243b',
                    }
                },
                outlinedSelected: {
                    background: 'transparent',
                    border: '1px solid #f8243b',
                    boxShadow: '0em 0px 6px 0px #f8243b',
                },
                menu: {
                    color: 'rgba(255,255,255,0.5)',
                    '&:hover': {
                        background:'transparent',
                        color: '#f8243b'
                    }
                }
            }
        }
    },

    palette: {
        mode: 'dark',
        primary: {
            main: '#f8243b',
        },
        secondary: {
            main: '#323946',
        },
        background: {
            secondary: '#252a33',
            paper: '#252830',
            default: '#1b1f27'
        },
        accent: {
            main: '#e6fc69',
        },
        text: {
            primary: '#ffffff',
        },
    },
});
