import React from "react";
import {Box, Chip, Grid, Stack, Typography, useMediaQuery} from "@mui/material";
import cover_bg from '../assets/images/cover_nera.jpg'
import tablet_home from '../assets/images/tablet_form.png'
import {winstonTheme} from "../theme/winstonTheme";
import {Testimonials} from "./Testimonials";
import {useTranslation} from "react-i18next";
import {LangSwitcher} from "./LangSwitcher";

function Claims({mobile}) {
    const {t} = useTranslation()
    const claims = [
        {label: t('claims.handle'), body: t('claims.handleDescr')},
        {label: t('claims.monitor'), body: t('claims.monitorDescr')},
        {label: t('claims.optimize'), body: t('claims.optimizeDescr')}
    ]

    return (
        claims.map((claim, i) => (
            <Stack key={i}
                   mt={i ? 2 : 4}
                   display={'flex'}
                   px={mobile ? 2 : 'inherit'}
                   direction={mobile ? 'column' : 'row'}
                   alignItems={'center'}
                   flexWrap={"wrap"} spacing={mobile ? 0 : 2}>
                {!mobile ?
                    <Chip variant={'outlined'}
                          label={claim.label}
                          style={{
                              borderRadius: '0.75rem',
                              width: '150px',
                              paddingInline: 25,
                              paddingBlock: 18,
                              fontSize: 'min(4.7vw, 1.363rem)',
                              color: winstonTheme.palette.primary.main
                          }}/>
                    : <Typography style={{
                        borderRadius: '0.75rem',
                        paddingInline: 25,
                        paddingBlock: mobile ? 0 : 18,
                        fontSize: '2rem',
                        fontWeight: 'bold',
                        //fontSize: 'min(4.7vw, 1.363rem)',
                        color: winstonTheme.palette.primary.main
                    }}>{claim.label}</Typography>
                }
                <Typography sx={{
                    textAlign: mobile ? 'center' : 'left',
                    fontSize: mobile ? '1.5rem' : 'min(4.7vw, 1.363rem)',
                    opacity: '0.5',
                    maxWidth: '350px',
                }}>{claim.body}</Typography>
            </Stack>))

    );
}

const MainSection = ({sectionRefs}) => {
    const {t} = useTranslation()

    const matches = useMediaQuery(winstonTheme.breakpoints.up("sm"));
    const mobile = useMediaQuery(winstonTheme.breakpoints.between("xs", "sm"));
    const xl = useMediaQuery(winstonTheme.breakpoints.up("xl"));

    return (
        <Box ref={sectionRefs[0]}>
            <Stack pt={5}
                   sx={{
                       width: '100%',
                       position: 'relative',
                       height: matches ? 'max(90vh, 720px)' : '',
                       backgroundImage: {
                           sm: `url(${cover_bg})`,
                           xs: ""
                       },
                       backgroundAttachment: 'fixed',
                       backgroundSize: 'cover'
                   }} alignItems={'center'} justifyContent={'center'}>
                <Grid container px={{xs: 0, sm: 2, md: 12}} justifyContent={{xs: 'center', lg: 'right'}}>
                    <Grid px={1} item xs={12} sm={10} md={10} lg={6} xl={5}>
                        <Typography sx={{
                            fontSize: matches ? '3.5rem' : '2.5rem',
                            lineHeight: matches ? '4.188rem' : '2.5rem',
                            fontWeight: 700,
                            marginBottom: '1.4rem',
                            textAlign: matches ? 'left' : 'center'
                        }}>
                            {t('mainSection.title')} <u style={{
                            textDecoration: `underline ${winstonTheme.palette.primary.main} 6px`,
                            textUnderlineOffset: '6px'
                        }}>Winston</u>
                        </Typography>
                        <p style={{
                            paddingInline: 4,
                            fontSize: 'min(4.8vw, 1.685rem)',
                            fontWeight: 400,
                            opacity: '0.5',
                            maxWidth: '670px',
                            marginTop: '1rem',
                            textAlign: matches ? 'left' : 'center'
                        }}>
                            {t('mainSection.subtitle')}
                        </p>
                        {mobile && <Testimonials isMobile={mobile}/>}
                        <Grid item xs={6} display={{sm: 'none', xs: 'inherit'}} mt={3}>
                            <img className={'el2'} src={tablet_home} width={'450px'}/>
                        </Grid>
                        <Claims mobile={mobile}/>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{
                            display: {lg: 'flex', xs: 'none'},
                            position: {lg: 'absolute'},
                            right: {xl: '-9rem', lg: '-10rem'},
                            top: {lg: '20%'},
                        }}>
                            <img className={'el2'} src={tablet_home} width={xl ? '850px' : '750px'}/>
                        </Box>
                    </Grid>
                </Grid>
            </Stack>
        </Box>
    )
}

export default MainSection
