import React from "react";
import {Box, lighten, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import {winstonTheme} from "../theme/winstonTheme";
import {useTranslation} from "react-i18next";

export const Footer = () => {
    const {t} = useTranslation()
    const theme = useTheme()
    const md = useMediaQuery(theme.breakpoints.down('lg'))
    return(
        <Box p={4} px={8} sx={{
            background: winstonTheme.palette.background.default,
            color: lighten(winstonTheme.palette.background.default, 0.35)
        }}
             display={'flex'}
             justifyContent={'space-between'}
             flexDirection={md ? 'column' : 'row'}
        >
                <Stack sx={{textAlign: 'center'}}
                     direction={md ? 'column' : 'row'}
                     alignItems={'center'}
                     spacing={3}
                >
                    <img style={{maxWidth:'8.813rem', width:'100%', objectFit: 'contain'}} src={require('../assets/images/logo_header.png')}/>
                    <Typography variant={'body1'} sx={{maxWidth: md ? '250px' : 'inherit'}}>
                        {t('footer.claim')}
                    </Typography>
                </Stack>
                <Stack mt={md ? 2 : 'inherit'}
                       direction={md ? 'column' : 'row'}
                       justifyContent={"space-between"}
                       alignItems={'center'}
                       spacing={2}>
                    {!md && <Typography variant={'body2'} sx={{fontWeight: 300}}>
                        ©2023 Plesh Tech. All Rights Reserved.
                    </Typography>}
                    <Typography variant={'body2'}>
                        <a style={{color:'inherit'}} href={'https://plesh.co/privacy-policy/'} target={'_blank'}>
                            Privacy
                        </a>
                    </Typography>
                    <Typography variant={'body2'}>
                        <a style={{color:'inherit'}} href={'https://plesh.co/cookie-policy/'} target={'_blank'}>
                            Cookies
                        </a>
                    </Typography>
                    {md && <Typography sx={{fontWeight: 300}}>
                        ©2023 Plesh Tech. All Rights Reserved.
                    </Typography>}
                </Stack>
        </Box>

    )
}
