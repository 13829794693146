import React, {useEffect, useMemo} from "react";
import {Box, Stack, Typography, useMediaQuery} from "@mui/material";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import {Autoplay} from "swiper";
import {winstonTheme} from "../theme/winstonTheme";
import {useTranslation} from "react-i18next";

export const Testimonials = ({agenzie = false}) => {
    const {t} = useTranslation()
    const mobileOnly = useMediaQuery(winstonTheme.breakpoints.only("xs"));

    const cache = {};

    function importAll(r) {
        r.keys().forEach((key) => {
            return (cache[key] = r(key))
        });
    }

    const images = useMemo(() => {
        // Note from the docs -> Warning: The arguments passed to require.context must be literals!
        // Attenzione: require.context fa partire il debugger
        agenzie ?
            importAll(require.context("../assets/images/agenzie/", false, /\.(png|jpe?g|svg)$/))
            : importAll(require.context("../assets/images/aziende/", false, /\.(png|jpe?g|svg)$/))

        return Object.entries(cache).map(module => {
            return module[1]
        });
    }, [])

    return <Box pt={2} pb={3.5} flexDirection={'column'} justifyContent={'center'}>
        <Typography variant={'h6'}
                    gutterBottom
                    fontWeight={'bold'}
                    textAlign={'center'}
                    color={'#adadad'}
                    sx={{
                        opacity: '0.5',
                        mb: 3,
                        letterSpacing: '0.2rem',
                        display: {lg: 'inherit', md: 'inherit', sm: 'inherit', xs: 'none'}
                    }}
        >
            {agenzie ?
                t('testimonials.agencies')
                : t('testimonials.companies')}
        </Typography>
        {
            mobileOnly ?
                <Swiper
                    spaceBetween={0}
                    slidesPerView={5}
                    autoplay={{
                        delay: 1,
                        disableOnInteraction: false
                    }}
                    className={agenzie ? 'agenzieSwiper' : 'aziendeSwiper'}
                    speed={agenzie ? 5000 : 4000}
                    loop={true}
                    modules={[Autoplay]}
                    allowTouchMove={true}
                >
                    {images.map((image, i) => (
                        <SwiperSlide
                            style={{paddingInline:'2rem'}}
                        >
                            <img key={i} src={image}
                                 width={'85px'}
                                 style={{
                                     objectFit: "contain",
                                     opacity: 0.5
                                 }}/></SwiperSlide>
                    ))}
                </Swiper>
                : <Stack spacing={10}
                      direction={'row'}
                      justifyContent={'center'}
                       flexWrap={'wrap'}
                >
                    {images.map((image, i) => (
                        <img key={i} src={image}
                                 width={'100px'}
                                 style={{
                                     objectFit: "contain",
                                     opacity: 0.5,
                                     paddingTop: '2%'
                                 }}/>
                    ))}
                </Stack>
        }
    </Box>;
}
