import React from "react";
import {alpha, Box, Divider, Stack, Typography, useMediaQuery} from "@mui/material";
import CustomCard from "./CustomCard";
import {winstonTheme} from "../theme/winstonTheme";
import useScrollSpy from "react-use-scrollspy";
import {useTranslation} from "react-i18next";

const cards = [
    {
        id: "userWebSites",
        icon: 'icon_dashboard.png', img: 'MacBook_Pro_sito_ferrari.png'
    },
    {
        id: "dashboardAnalytics",
        icon: 'lingua.png', img: 'ipad_dashboard.png'
    },
    {
        id: "automaticCommunications",
        icon: 'icon_message.png', img: 'people.png'
    },
    {
        id: "dbManagement",
        icon: 'icon_db.png', img: 'dbMng_cover.png'
    },
    {
        id: "checkin",
        icon: 'icon_smartphone.png', img: 'check_in_cover.png'
    },
    {
        id: "dedicatedTeam",
        icon: 'icon_dedicatedTeam.png',
    }
]

function Servizio({content, alternate, last, mobile, serviziSectionsRef}) {
    const {t} = useTranslation()
    const mdXl = useMediaQuery(winstonTheme.breakpoints.between("md", "xl"));
    return (
        <Box id={content.id} ref={serviziSectionsRef} px={!mobile ? 6 : 4} pb={3} mb={2}>
            {
                !mobile && alternate ?
                    <Stack direction={'row'} display={'flex'} flexWrap={mobile ? 'wrap' : 'inherit'}
                           justifyContent={{xs: 'start', sm: 'center'}} alignItems={'center'}>
                        <Box maxWidth={{xl: '650px', lg: '550px', md: '400px', sm: '350px'}}>
                            <Typography gutterBottom variant={mdXl ? 'h3' : 'h4'} fontWeight={'bold'}>
                                {t(`services.${content.id}Title`)}
                            </Typography>
                            <Typography variant={'h5'} fontWeight={300}
                                sx={{lineHeight: '1.8rem'}}>
                                {t(`services.${content.id}Body`)}
                            </Typography>
                        </Box>
                        <img style={{paddingInline: 0, maxWidth: !mobile ? '500px' : '400px'}}
                             src={require('../assets/images/servizi/covers/' + content.img)}/>
                    </Stack>
                    :
                    <Stack direction={'row'} display={'flex'} flexWrap={mobile ? 'wrap' : 'inherit'}
                           justifyContent={{xs: 'center', sm: 'space-evenly'}} alignItems={'center'}>
                        <img style={{paddingInline: 0, maxWidth: !mobile ? '500px' : '350px'}}
                             src={require('../assets/images/servizi/covers/' + content.img)}/>
                        <Box maxWidth={{xl: '650px', lg: '550px', md: '400px', sm: '350px'}}>
                            <Typography gutterBottom variant={mdXl ? 'h3' : 'h4'} fontWeight={'bold'}>
                                {t(`services.${content.id}Title`)}
                            </Typography>
                            <Typography variant={'h5'} fontWeight={300}
                                sx={{lineHeight: '1.8rem'}}
                            >
                                {t(`services.${content.id}Body`)}
                            </Typography>
                        </Box>
                    </Stack>
            }
            {mobile && !last &&
                <Divider color={winstonTheme.palette.primary.main}
                         sx={{width: '35%', height: '4px', marginX: 'auto', marginTop: 6, borderColor: 'transparent'}}/>}
        </Box>
    );
}

const Funzioni = ({sectionRefs, serviziSectionsRefs}) => {
    const {t} = useTranslation()
    const mobile = useMediaQuery(winstonTheme.breakpoints.between('xs', 'lg'))

    const activeSection = useScrollSpy({
        sectionElementRefs: serviziSectionsRefs.concat(sectionRefs[3]), // Array of References to DOM elements
        offsetPx: -500,
        throttleMs: 10
    });

    return (
        <Stack id={'funzioni'} ref={sectionRefs[2]} mt={2} pt={4} pb={8} justifyContent={'center'}>
            <Typography gutterBottom textAlign={'center'} variant={'h4'} fontWeight={'bold'}>
                {t('services.title')}
            </Typography>
            <Stack direction={{lg: 'row', md: 'row', sm: 'row', xs: 'column'}}
                   flexWrap={'wrap'}
                   sx={{position: {lg: 'sticky'}, top: {lg: '-2rem'}}}
                   justifyContent={'center'}
                   my={4}
                   mx={mobile ? 1 : 'inherit'}
            >
                {
                    cards.map((c, i) => (
                        <Box key={i} id={'services-btn'} m={1} width={{lg: '180px', md: '200px', sm: '200px'}}
                             onClick={() => {
                                 /*if(!mobile)
                                     setSelected(c.id)*/
                                 document.getElementById(c.id)
                                     .scrollIntoView({behavior: "smooth", block: 'center', inline: "center"});
                             }}
                        >
                            <CustomCard variant={c.id === 'dedicatedTeam'
                                ? 'servizi-dedicated-team' :
                                mobile ? 'servizi'
                                    : activeSection === i+1 ?
                                        'servizi-selected' : 'servizi'}
                                        content={c}/>
                        </Box>
                    ))
                }
            </Stack>
            {
                cards.map((c, i) => (
                    c.id !== "dedicatedTeam" ?
                            <Servizio key={c.id} content={c} alternate={i % 2 === 0} last={i === cards.length - 2}
                                      mobile={mobile}
                                      serviziSectionsRef={serviziSectionsRefs[i+1]}/>
                        : <Box ref={serviziSectionsRefs[i+1]}/>
                ))
            }
        </Stack>
    );
}

export default Funzioni
