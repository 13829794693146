import React, {useRef} from "react";
import {Box, useMediaQuery} from "@mui/material";
import {Testimonials} from "../components/Testimonials";
import Funzioni from "../components/Funzioni";
import DedicatedTeam from "../components/DedicatedTeam";
import RichiediDemo from "./RichiediDemo";
import {FloatingButton} from "../components/RichiediDemoButton";
import MainSection from "../components/MainSection";
import {CustomHeader} from "../App";
import {winstonTheme} from "../theme/winstonTheme";
import {Footer} from "../components/Footer";


const Home = () => {
    const matches = useMediaQuery(winstonTheme.breakpoints.up("sm"));
    const sectionRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];

    const serviziSectionsRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];

    return (
        <>
            <CustomHeader matches={matches} sectionRefs={sectionRefs}/>
            <Box ref={serviziSectionsRefs[0]}>
                <MainSection sectionRefs={sectionRefs}/>
                <Box mb={10} id={'clienti'} ref={sectionRefs[1]}
                     sx={{background: `linear-gradient(0deg, #252a33 0%, #1b1f27 50%)`}}>
                    <Box py={3} sx={{display: {lg: 'inherit', md: 'inherit', sm: 'inherit', xs: 'none'}}}>
                        <Testimonials/>
                        <Testimonials agenzie/>
                    </Box>
                    <Funzioni sectionRefs={sectionRefs} serviziSectionsRefs={serviziSectionsRefs}/>
                </Box>
                <Box mt={5} sx={{background: `linear-gradient(0deg, #252a33 0%, #1b1f27 50%)`}}>
                    <DedicatedTeam sectionRefs={sectionRefs}/>
                    <RichiediDemo sectionRefs={sectionRefs}/>
                </Box>
                <FloatingButton/>
            </Box>
            <Footer/>
        </>
    )
}

export default Home
